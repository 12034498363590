// Fonts
@font-face {
    font-family: 'Concourse';
    src: url('/assets/fonts/Concourse/Concourse OT 3 Book.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'brandon-grotesque';
    src: url('/assets/fonts/brandon-grotesque/Brandon_reg.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'BG-italic';
    src: url('/assets/fonts/brandon-grotesque/Brandon_reg_it.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'BG-bold';
    src: url('/assets/fonts/brandon-grotesque/Brandon_bld.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'League-Spartan';
    src: url('/assets/fonts/league-spartan/LeagueSpartan-Bold.otf') format('opentype');
    font-display: swap;
}

$font-header: 'League-Spartan', sans-serif;
$font-body: 'Concourse', 'brandon-grotesque', sans-serif;
$font-bold: 'Concourse', 'BG-bold', sans-serif;
$font-italic: 'Concourse', 'BG-italic', sans-serif;

// Colors
$black: #353535;
$white: #efefef;
$red: #e82524;
$primary: $red;
