.clearfix {
    overflow: auto;
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}
%pos-abs-full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.h-full {
    height: 100vh;
}
.bg {
    &-img {
        @extend %pos-abs-full;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: 0.4s;
        &__container {
            position: relative;
            &--square {
                width: 75%;
                height: 0;
                padding-bottom: 75%;
            }
        }
        &--contain {
            background-size: contain;
        }
    }
    &-overlay {
        &::after {
            @extend %pos-abs-full;
            // background-image: linear-gradient(to bottom, transparent, transparent 50%, fade-out($primary, 0.5));
            background-color: fade-out($primary, 0.75);
            content: "";
        }
    }
    &-white {
        background-color: $white;
    }
    &-black {
        background-color: $black;
    }
    &-red {
        background-color: $red;
    }
}
.text {
    &-white {
        color: #fff;
    }
    &-black {
        color: $black;
    }
    &-red {
        color: $red;
    }
}
.columns {
    &--two {
        columns: 2;
        column-gap: 3rem;
    }
}
