a {
    color: $black;
    text-decoration: underline;
    &:active, &:focus, &:hover {
        color: $primary;
        outline: none;
    }
    .wysiwyg & {
        padding: 0.25rem 1rem;
        color: $black;
        text-decoration: none;
        background-color: transparent;
        border: 2px solid $black;
        border-radius: 5px;
        &:active, &:focus, &:hover {
            outline: none;
            color: $white;
            text-decoration: none;
            background-color: $primary;
            border-color: $primary;
        }
    }
    // :not(.site-footer__media--button):not(.hero-slide__contact--media-button):not(.carousel-control-*)
}
