body {
    font-family: $font-body;
    font-size: 1.25rem;
    &.modal-open {
        overflow-y: hidden;
    }
}
.container, .container-lg, .container-md, .container-sm, .container-xl {
    @media (min-width: 1200px) {
        max-width: 960px;
    }
}
header {
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    z-index: 2;
}
.skip-link.screen-reader-text {
    position: absolute;
    left: -1000vw;
}
.content-wrapper {
    position: relative;
    z-index: 1;
    // &.modal-open {
    //     z-index: 3;
    // }
}
.wrapper {
    position: relative;
    z-index: 1;
}
.hero-slide {
    min-height: 100vh;
    min-height: -webkit-stretch;
    &__home {
        &--carousel {
            margin-bottom: 4rem;
            @media (min-width: 576px) {
                padding-top: 0;
                margin-bottom: 0;
            }
        }
    }
    &__about {
        &--lead-in {
            margin-bottom: 1.5rem;
            @media (min-width: 768px) {
                float: left;
                // margin-right: 4rem;
                margin-right: 2.5rem;
                transfrom: translateY(10px);
            }
        }
        &--image {
            position: relative;
            width: 250px;
            height: 250px;
            // border-radius: 50%;
            border-radius: 2px;
            border: 2px solid $black;
            overflow: hidden;
            @media (min-width: 768px) {
                width: 300px;
                height: 300px;
            }
            &-container {
                position: relative;
                top: 50%;
                left: 50%;
                width: calc(100% - 2rem);
                height: calc(100% - 2rem);
                transform: translate(-50%, -50%);
                // border-radius: 50%;
                border-radius: 2px;
                overflow: hidden;
            }
        }
        .wysiwyg {
            text-align: justify;
        }
    }
    &__work {
        &--video {
            iframe {
                width: 100%;
                height: 360px;
                @media (min-width: 768px) {
                    width: 640px;
                }
            }
        }
        &--head-vimeo {
            overflow: visible;
            z-index: 2;
            transition: 0.2s;
            .modal-background {
                position: fixed;
                top: -6rem;
                left: -12.5vw;
                width: 100vw;
                height: 100vh;
                background-image: linear-gradient($black, $red);
                opacity: 0;
                pointer-events: none;
                transition: 0.2s;
            }
            .tilted-media__item {
                position: relative;
                transition: 0.2s;
            }
            .modal-close__button {
                position: absolute;
                top: 0;
                right: -35px;
                width: 30px;
                height: 30px;
                background: $white;
                border: 2px solid $black;
                border-radius: 4px;
                cursor: pointer;
                pointer-events: none;
                opacity: 0;
                transition: 0.1s;
                &::before, &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    top: 50%;
                    left: 50%;
                    width: 80%;
                    height: 2px;
                    background: $black;
                }
                &::before {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                &::after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
                &:hover {
                    background: $red;
                }
            }
            &.modal-open {
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                width: 75vw;
                height: 42.1875vw;
                .modal-background {
                    opacity: 0.75;
                }
                .tilted-media__item {
                    transform: none!important;
                }
                .modal-close__button {
                    pointer-events: all;
                    opacity: 1;
                }
            }
        }
    }
    &__photos {
        &--grid {
            .carousel-item {
                &.active, &-prev, &-next {
                    display: flex;
                }
            }
            .carousel-indicators {
                position: static;
                margin: 0 0 1rem 0;
                @media (min-width: 768px) {
                    margin: 0 15%;
                }
                li {
                    width: auto;
                    height: auto;
                    margin: 0;
                    text-indent: 0;
                    cursor: pointer;
                    background: none;
                    background-clip: border-box;
                    border: 0;
                    opacity: 1;
                    transition: 0.1s;
                    padding: 1rem;
                    &.active span, &:hover span {
                        border-bottom: 2px solid $black;
                    }
                }
            }
            &-photos {
                display: flex;
                margin-left: 0;
                transition: 0.4s;
                &.in-transition {
                    opacity: 0!important;
                }
            }
            &-photo {
                position: relative;
                height: 0;
                padding-bottom: calc((100% - 0.375rem) / 3);
                border: 1px solid #fff;
                cursor: pointer;
                @media (min-width: 768px) {
                    padding-bottom: calc((100% - 6rem) / 3);
                    border: 2px solid #fff;
                    border-bottom-width: 1rem;
                }
                &:nth-child(3n-1) {
                    @media (min-width: 768px) {
                        border-left-width: 1rem;
                        border-right-width: 1rem;
                    }
                }
            }
        }
        &--modal {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-color: fade-out($black, 0.25);
            opacity: 0;
            pointer-events: none;
            z-index: 11;
            transition: 0.2s;
            &.modal-open {
                opacity: 1;
                pointer-events: all;
            }
            &-carousel {
                position: absolute;
                display: none;
                top: 40%;
                left: 50%;
                width: 90vw;
                height: 90vw;
                transform: translate(-50%, -50%);
                @media (min-width: 576px) {
                    top: 50%;
                    width: 50vw;
                    height: 50vw;
                }
                &.modal-open {
                    display: block;
                }
                .carousel-inner {
                    height: 100%;
                }
                .carousel-item {
                    height: 100%;
                }
            }
            &-close {
                position: absolute;
                top: 1rem;
                right: 1rem;
                width: 30px;
                height: 30px;
                background: $white;
                border: 2px solid $black;
                border-radius: 4px;
                cursor: pointer;
                transition: 0.1s;
                &::before, &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    top: 50%;
                    left: 50%;
                    width: 80%;
                    height: 2px;
                    background: $black;
                }
                &::before {
                    transform: translate(-50%, -50%) rotate(45deg);
                }
                &::after {
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
                &:hover {
                    background: $red;
                }
            }
        }
    }
    &__contact {
        &--image {
            margin-bottom: 4rem;
            @media (min-width: 768px) {
                margin-bottom: 0;
            }
            img {
                width: 200px;
                @media (min-width: 768px) {
                    width: 400px;
                }
            }
        }
        &--media {
            font-size: 1.5rem;
        }
    }
}
.carousel {
    &-container {
        transform: rotateY(0deg) rotateZ(-5deg) translateX(0);
        padding: 1rem;
        border: 2px solid black;
        border-radius: 5px;
    }
    .hero-slide__work--image-gallery & {
        width: 100%;
        @media (min-width: 768px) {
            width: 50%;
        }
        .carousel-item {
            padding-bottom: 100%;
        }
    }
    .hero-slide__home & .carousel-item {
        padding-bottom: 75%
    }
}
.tilted-media {
    &__row {
        position: relative;
    }
    &__text {
        height: 28.125vw;
        margin-bottom: 5rem;
    }
    &__container {
        position: relative;
        top: 0;
        left: 50%;
        width: 75vw;
        height: auto;
        perspective: 1200px;
        perspective-origin: 80% 80%;
        margin-top: 3rem;
        margin-bottom: 3rem;
        transform: translateX(-50%);
        @media (min-width: 768px) {
            position: absolute;
            top: 3rem;
            left: 45%;
            width: 50vw;
            height: 28.125vw;
            margin-top: 0;
            margin-bottom: 0;
            transform: translateX(0);
        }
    }
    &__item {
        width: 100%;
        height: 100%;
        transform: rotateX(3deg) rotateY(-25deg) rotateZ(1deg)!important;
        iframe {
            width: 100%;
            height: 100%;
            border: 0;
        }
        &--left {
            transform: rotateX(3deg) rotateY(-25deg) rotateZ(1deg)!important;
            &.overlap-neighbor {
                transform: rotateX(3deg) rotateY(-25deg) rotateZ(1deg) translateX(-35%)!important;
            }
        }
        &--right {
            transform: rotateX(-3deg) rotateY(25deg) rotateZ(-1deg)!important;
            &.overlap-neighbor {
                transform: rotateX(-3deg) rotateY(25deg) rotateZ(-1deg) translateX(35%)!important;
            }
        }
    }
}
footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
}
.site-footer {
    &__copyright {
        font-size: 1rem;
    }
    &__media {
        font-size: 1.5rem;
        &--button {
            color: $white;
            &:active, &:focus, &:hover {
                color: $primary;
            }
        }
    }
}
